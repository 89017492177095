<template>
  <div
    ref="channelsContainerRef"
    class="activity-channels relative flex flex-col w-full h-full lg:border-r"
  >
    <div class="activity-channels-header px-2 py-4">
      <div
        class="activity-channels--heading bg-surface-0 flex justify-between items-center shrink-0"
      >
        <p class="font-medium text-primary text-xl">
          <font-awesome-icon class="mr-2" :icon="['far', 'walkie-talkie']" />
          Messages & Channels
        </p>
        <div class="h-max">
          <Btn
            class="rounded-r-none rounded-l-none bg-transparent h-10 w-10 ml-2 hover:bg-surface-200 lg:hidden"
            @click="onCloseClick"
          >
            <font-awesome-icon :icon="['fas', 'xmark']" class="text-surface-900" />
          </Btn>
          <ProgressSpinner
            v-if="allChannelsLoading"
            :pt="{ root: 'h-8 w-8', spinner: 'w-full h-full' }"
          />
        </div>
      </div>
      <div class="activity-channels--filters shrink-0 bg-surface-0 flex items-center py-4 gap-2">
        <div class="flex justify-center items-center gap-2 h-fit max-h-fit w-full">
          <IconField iconPosition="left" class="w-full">
            <InputIcon>
              <font-awesome-icon icon="search" class="text-surface-900" />
            </InputIcon>
            <InputText
              v-model="searchPhrase"
              placeholder="Search your channels..."
              type="text"
              class="!min-w-[100px]"
            />
          </IconField>
        </div>
        <Btn
          size="lg"
          severity="primary-black"
          v-tooltip="'Create a channel...'"
          @click="createChannel"
        >
          <font-awesome-icon :icon="['fas', 'plus']" />
        </Btn>
      </div>
      <div
        class="activity-channels--filters shrink-0 bg-surface-0 flex items-center overflow-x-auto scrollbar-hide"
      >
        <div
          v-for="(filter, index) in filters"
          @click="() => onFilterClick(index)"
          class="activity-channels--filter select-none flex px-4 py-1 min-w-16 items-center justify-center rounded-full mr-2 last:mr-0 border cursor-pointer"
          :class="{
            'bg-black text-white border-surface-0': index === selectedFilterIndex,
            ' hover:bg-pitch-black/5': index !== selectedFilterIndex
          }"
          :key="index"
        >
          <p class="font-normal text-sm">{{ filter }}</p>
        </div>
      </div>
    </div>
    <div class="activity-channels--channels px-2 overflow-y-auto">
      <div v-if="filteredChannels.length > 0" class="h-full w-full">
        <div
          v-for="channel in filteredChannels"
          :key="channel.channel_id"
          @click="() => onChannelClick(channel)"
          class="activity-channels--channel flex cursor-pointer hover:bg-surface-100 px-4 py-3 rounded relative overflow-hidden"
          :class="{
            'bg-cement-100':
              (channel.channel_type === currentChannelType &&
                channel.channel_type_id === currentChannelTypeId) ||
              channel.parent_channel_id === `${currentChannelType}-${currentChannelTypeId}`
          }"
        >
          <div class="activity-channels--channel--avatar relative w-12 h-12 mr-4">
            <div
              v-if="
                channel.channel_type !== 'CHAT' ||
                (channel.channel_members.items.length && channel.channel_members.items.length > 2)
              "
              class="h-12 w-12 inline-flex items-center justify-center"
            >
              <img
                v-if="channel.channel_type === 'SUPPORT'"
                src="@/assets/SVG/Bolster_Icon_Black.svg"
                alt="bolster icon"
                class="w-8 h-8"
              />
              <font-awesome-icon
                v-else-if="channel?.channel_type !== 'CHAT'"
                size="xl"
                :icon="[
                  'far',
                  channel.channel_type === 'COMPANY'
                    ? 'scroll'
                    : channel.channel_type === 'COMPANY_GENERAL'
                      ? 'walkie-talkie'
                      : 'house-building'
                ]"
              />
              <font-awesome-icon v-else :icon="['far', 'hashtag']" />
              <PersonAvatar
                v-if="channel.channel_type === 'QUOTE' || channel.channel_type === 'QUOTE'"
                shape="circle"
                :name="`${channel.client_fname} ${channel.client_lname}`"
                :id="channel.client_user_id"
                :full="true"
                class="h-5 w-5 absolute right-1 bottom-1 inline-flex items-center justify-center rounded-full bg-surface-0 border"
                size="no"
              />
            </div>
            <PersonAvatar
              v-else
              shape="circle"
              :name="getFormattedChannelName(channel)"
              :full="true"
              class="h-12 w-12 inline-flex items-center justify-center rounded-full bg-surface-0 border"
              size="no"
            />
          </div>
          <div class="activity-channels--channel--details flex-1 overflow-hidden">
            <p class="font-medium text-sm text-ellipsis overflow-hidden">
              {{ getFormattedChannelName(channel) }}
              <span
                v-if="channel?.channel_status && getStatusCodeName(channel.channel_status)"
                class="ml-1 inline-block text-xs font-medium p-0.5 rounded"
                :class="{
                  'bg-sky-100 text-sky-800': channel.channel_status === 'p',
                  'bg-emerald-100 text-emerald-800': channel.channel_status === 'k',
                  'bg-violet-100 text-violet-800': channel.channel_status === 'f',
                  'bg-red-300 text-red-800': channel.channel_status === 'g'
                }"
              >
                {{ getStatusCodeName(channel.channel_status) }}
              </span>
            </p>
            <div v-if="channel.latest_channel_activity" class="w-full overflow-hidden">
              <p
                class="text-xs font-normal text-ellipsis overflow-hidden mb-1"
                v-html="
                  formatMessage(
                    channel.latest_channel_activity.activity.activity_notification_heading
                  )
                "
              />
              <p class="text-xs font-normal text-surface-300 text-ellipsis overflow-hidden">
                {{
                  getFormattedTimeCreated(
                    channel.latest_channel_activity.activity.activity_time_created
                  )
                }}
              </p>
            </div>
            <p class="text-xs font-normal text-ellipsis overflow-hidden" v-else>No activity</p>
          </div>
          <div class="activity-channels--channel--notifications absolute top-3 right-4">
            <Badge
              v-if="getTotalChannelNotificationsLength(channel.channel_id)"
              :value="
                getTotalChannelNotificationsLength(channel.channel_id) > 99
                  ? '99+'
                  : `${getTotalChannelNotificationsLength(channel.channel_id)}`
              "
              :pt="{
                root: '!size-6 rounded-full text-white/80 dark:text-surface-900 bg-secondary flex justify-center items-center text-[8pt] !font-light'
              }"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-center text-surface-300">
          <span class="mr-1"><font-awesome-icon icon="circle-xmark" /></span> No channels
        </p>
      </div>
    </div>
    <div
      draggable="true"
      @drag="resizeMenu"
      class="hidden md:block absolute top-1/2 right-0 text-cement-600 pl-2 pr-1 z-20 cursor-col-resize"
    >
      <font-awesome-icon icon="grip-lines-vertical" size="xl" />
    </div>
    <ActivityChannelCreateModal ref="activityChannelCreateRef" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Badge from 'primevue/badge'
import ProgressSpinner from 'primevue/progressspinner'
import { useActivityChannels } from '@/components/composables/ActivityChannels'
import ActivityChannelCreateModal from '@/components/Activities/ActivityChannelCreateModal.vue'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'

// ======== Composables ======== //
const store = useStore()
const router = useRouter()
const { getFormattedChannelName, getTotalChannelNotificationsLength, getStatusCodeName } =
  useActivityChannels()
// ====================== //

// ======== Emits ======== //
const emit = defineEmits(['close', 'onResize'])
// ====================== //

// ======== Refs ======== //
const channelsContainerRef = ref(null)
const activityChannelCreateRef = ref(null)
// ====================== //

// ======== Data ======== //
const filters = ref(['All', 'Unread', 'Groups', 'Direct', 'Archived'])
const selectedFilterIndex = ref(0)
const searchPhrase = ref('')
// ====================== //

// ======== Computed ======== //
const allChannelsLoading = computed(() => store.state.activityChat.channelsLoading)
const currentChannelType = computed(() => {
  return router.currentRoute.value.params.channelType
})
const currentChannelTypeId = computed(() => {
  return router.currentRoute.value.params.channelTypeId
})
const channels = computed(() => {
  return Object.values(store.state.activityChat.channels)
})
const companyId = computed(() => store.state.session.company.company_id.toString())
const filteredChannels = computed(() => {
  return channels.value
    .filter((channel) => {
      if (channel.company_id && channel.company_id !== companyId.value) {
        return false
      }

      if (
        channel?.parent_channel_id !== 'NULL' &&
        store.state.activityChat.channels[channel.parent_channel_id]
      ) {
        return false
      }

      const filtered = (channel.channel_name || '')
        .toLowerCase()
        .includes(searchPhrase.value.toLowerCase())

      if (!filtered) {
        return false
      }

      switch (selectedFilterIndex.value) {
        case 0:
          return !channel.archived
        case 1:
          return (
            !channel.archived &&
            store.state.activityChat.channelNotifications[channel.channel_id]?.length
          )
        case 2:
          return !channel.archived && channel.channel_members?.items?.length > 2
        case 3:
          return (
            !channel.archived &&
            channel.channel_type === 'CHAT' &&
            channel.channel_members?.items?.length <= 2
          )
        case 4:
          return channel.archived
        default:
          return !channel.archived && true
      }
    })
    .sort((a, b) => {
      if (a.latest_channel_activity && !b.latest_channel_activity) return -1
      if (!a.latest_channel_activity && b.latest_channel_activity) return 1
      if (a.latest_channel_activity && b.latest_channel_activity) {
        return (
          b.latest_channel_activity?.activity.activity_time_created -
          a.latest_channel_activity?.activity.activity_time_created
        )
      }
      return 0
    })
})
// ====================== //

// ======== Methods ======== //
const getFormattedTimeCreated = (unixSeconds) => {
  const date = new Date(unixSeconds * 1000)
  return date.toLocaleDateString('default', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}
const onChannelClick = (channel) => {
  const scopeRoute = router.currentRoute.value.params.scopeRoute
  const context = router.currentRoute.value.meta.menuContext || 'company'
  const route = channel
    ? `/${scopeRoute}/${context === 'client' ? 'client-' : ''}messages/${channel.channel_type}/${channel.channel_type_id}`
    : `/${scopeRoute}/${context === 'client' ? 'client-' : ''}messages`
  router.push(route)
  emit('close')
}
const onFilterClick = (index) => {
  selectedFilterIndex.value = index
}
const onCloseClick = () => {
  emit('close')
}
const formatMessage = (message) => message.replaceAll(' ', '&nbsp;').replaceAll('\n', '<br>')
const resizeMenu = (event) => {
  const containerOffset = channelsContainerRef.value.getBoundingClientRect().left

  if (event.x - containerOffset + 10 > 0) {
    emit('onResize', event.x - containerOffset + 10)
  }
}
const createChannel = () => {
  activityChannelCreateRef.value.open()
}
// ====================== //
</script>

<style lang="less" scoped></style>
